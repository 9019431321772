import React from "react";
import LazyLoad from 'react-lazyload';
import "./Instalaciones.css";

// Import images
import imagen3 from "../../assets/instalaciones3.jpg";

const Installations = () => {
  return (
    <div className="installations-container">
      <div className="installations-header">
        <h2>PABELLÓN ANTONIO CABA</h2>
        <p>EL HOGAR DEL BALONCESTO MANZANARES</p>
      </div>
      <div className="installations-content">
        <div className="image-container">
          <LazyLoad height={200} offset={100}>
            <img
              src={imagen3}
              alt="installation"
              className="installation-image"
            />
          </LazyLoad>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2579.6231289916414!2d-3.3746849893869926!3d39.00636784015048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd691466845ec377%3A0xa18140bd4a4410f9!2sPabell%C3%B3n%20Polideportivo%20Municipal%20%E2%80%9CANTONIO%20CABA%E2%80%9D%20de%20Manzanares.!5e1!3m2!1ses!2ses!4v1721122147661!5m2!1ses!2ses"
            className="map-iframe"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Installations;
