import React from "react";
import "./ScrollToTopButton.css";

const ScrollToTopButton = ({ isVisible, onClick }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <button className="scroll-to-top" onClick={onClick}>
      𖤂
    </button>
  );
};

export default ScrollToTopButton;
