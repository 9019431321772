import React from 'react';
import './Preloader.css';
import basketball from '../../assets/preloader.png';

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={basketball} alt="Loading..." className="preloader-bounce" />
      <div className="shadow"></div>
    </div>
  );
};

export default Preloader;
