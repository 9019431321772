import React from 'react';
import Equipo from './Equipo';

import entrenadorJunior from './fotosyunior/3-min.png';
import entrenadorJuniorHover from './fotosyunior/4-min.png';
import carlosFriginal from './fotosyunior/Carlos Friginal.png';
import carlosFriginalHover from './fotosyunior/Carlos Friginal 2.png';
import juanGonzalez from './fotosyunior/Juan Gonzalez.png';
import juanGonzalezHover from './fotosyunior/Juan Gonzalez 2.png';
import robertoJuarez from './fotosyunior/Roberto Juarez.png';
import robertoJuarezHover from './fotosyunior/Roberto Juarez 2.png';
import hectorPenia from './fotosyunior/Hector Pen╠âa.png';
import hectorPeniaHover from './fotosyunior/Hector Pen╠âa 2.png';
import marioBulai from './fotosyunior/Mario Bulai.png';
import marioBulaiHover from './fotosyunior/Mario Bulai 2.png';
import santiagoLara from './fotosyunior/Santiago Lara.png';
import santiagoLaraHover from './fotosyunior/Santiago Lara 2.png';
import alejandroFierrez from './fotosyunior/Alejandro_fierrez_1.png';
import alejandroFierrezHover from './fotosyunior/Alejandro_fierrez_2.png';
import gabrielLorente from './fotosyunior/Gabriel Lorente - Base.png';
import gabrielLorenteHover from './fotosyunior/Gabriel Lorente 2.png';
import gustavoRodriguez from './fotosyunior/Gustavo Rodriguez Base.png';
import gustavoRodriguezHover from './fotosyunior/Gustavo Rodriguez 2.png';
import javiguerrero from './fotosyunior/unknown_1.png';
import javiguerreroHover from './fotosyunior/unknown_2.png';

const teamBPlayers = [
  { image: entrenadorJunior, hoverImage: entrenadorJuniorHover, number: "", name: 'Ricky', position: 'ENTRENADOR' },
  { image: carlosFriginal, hoverImage: carlosFriginalHover, number: 10, name: 'Carlos Friginal', position: 'ALA PIVOT' },
  { image: juanGonzalez, hoverImage: juanGonzalezHover, number: 14, name: 'Juan González', position: 'BASE' },
  { image: robertoJuarez, hoverImage: robertoJuarezHover, number: 23, name: 'Roberto Juárez', position: 'ESCOLTA' },
  { image: hectorPenia, hoverImage: hectorPeniaHover, number: 71, name: 'Héctor Peña', position: 'ALERO' },
  { image: marioBulai, hoverImage: marioBulaiHover, number: 12, name: 'Mario Bulai', position: 'BASE' },
  { image: santiagoLara, hoverImage: santiagoLaraHover, number: 16, name: 'Santiago Lara', position: 'ALERO' },
  { image: alejandroFierrez, hoverImage: alejandroFierrezHover, number: 17, name: 'Alejandro Fierrez', position: 'PIVOT' },
  { image: javiguerrero, hoverImage: javiguerreroHover, number: 1, name: 'Javi Guerrero', position: 'ALA-PIVOT' },
  { image: gabrielLorente, hoverImage: gabrielLorenteHover, number: 15, name: 'Gabriel Lorente', position: 'BASE' },
  { image: gustavoRodriguez, hoverImage: gustavoRodriguezHover, number: 24, name: 'Gustavo Rodríguez', position: 'BASE' },
];

teamBPlayers.sort((a, b) => a.number - b.number);

const TeamB = () => {
  return (
    <Equipo teamName="Team B" players={teamBPlayers} />
  );
};

export default TeamB;
