import React, { useState, useEffect } from 'react'; // Add useState and useEffect here
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css'; 
import Header from './components/Header2/Header2';
import Home from './components/Home/Home';
import Noticias from './components/Noticias/Noticias.jsx'
import Redes from './components/Redes/Redes';
import Patrocinador1 from './components/Patrocinador1/Patrocinador1';
import Patrocinadores from './components/Patrocinadores/Patrocinadores';
import Galeria from './components/Galeria/Galeria';
import Patrocinador2 from './components/Patrocinador2/Patrocinador2';
import Plantilla from './components/Plantilla/Plantilla';
import Instalaciones from './components/Instalaciones/Instalaciones';
import Patrocinadores4 from './components/Patrocinadores4/Patrocinadores4';
import Escuelas from './components/Escuelas/Escuela';
import EscuelaImages from './components/Escuelas/EscuelaImages'; // Import the new component
import Footer from './components/Footer/Footer';
import TeamA from './components/Equipos/TeamA';
import TeamB from './components/Equipos/TeamB';
import Historia from './components/Historia/ElClub';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import CookieBanner from './components/CookieBanner/CookieBanner';
import Preloader from './components/Preloader/Preloader';
import ResultadosEscuelas from './components/Resultados/ResultadosEscuelas';
import ResultadosJunior from './components/Resultados/ResultadosJunior';
import ResultadosSenior from './components/Resultados/ResultadosSenior';
import { Analytics } from "@vercel/analytics/react"

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const AppContent = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Simulamos una carga de 3 segundos

    return () => clearTimeout(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="App">
      {/* Modify this condition to exclude /escuela-images route */}
      {location.pathname !== '/teamA' && location.pathname !== '/teamB' && location.pathname !== '/escuela-images' && <Header />}
      <Routes>
        <Route path="/" element={
          <>
            <div id="home">
              <Home />
            </div>
            <Noticias/>
            <ResultadosEscuelas />
            <ResultadosJunior />
            <ResultadosSenior />
            <div id="redes">
              <Redes />
            </div>
            <div id="patrocinador1">
              <Patrocinador1 />
            </div>
            <Historia />
            <h2 id="patrocinadores-title">PATROCINADORES</h2>
            <div id="patrocinadores-content">
              <Patrocinadores />
            </div>
            <h2 id="galeria-title">ÚNETE A LA FAMILIA DEL C.B. MANZANARES</h2>
            <div id="galeria-content">
              <Galeria />
            </div>
            <h2 id="colaboradores-title">COLABORADORES</h2>
            <div id="colaboradores-content">
              <Patrocinador2 />
            </div>
            <div id="plantilla">
              <Plantilla />
            </div>
            <div id="escuelas">
              <Escuelas />
            </div>
            <div id="instalaciones">
              <Instalaciones />
            </div>
            <div id="patrocinadores4">
              <Patrocinadores4 />
            </div>
            <CookieBanner />
            <Footer />
            <Analytics/>
          </>
        } />
        <Route path="/teamA" element={<TeamA />} />
        <Route path="/teamB" element={<TeamB />} />
        <Route path="/escuela-images" element={<EscuelaImages />} /> {/* Add the new route */}
      </Routes>
      <ScrollToTopButton isVisible={isVisible} onClick={scrollToTop} />
    </div>
  );
};


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
