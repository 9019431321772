import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./styles.css";
import bgImage1 from "../../assets/escuelabg1.jpg"; // Solo importa la imagen que vas a usar

const MyComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    navigate('/escuela-images'); // Navigate to the new page
  };

  return (
    <div
      className="fade"
      style={{
        backgroundImage: `url(${bgImage1})`, // Usa solo la imagen escuelabg1
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px",
      }}
    >
      <div className="escuelas-content">
        <h2>ESCUELAS DEPORTIVAS</h2>
        <p>
          En el CB Manzanares, nos enorgullece ofrecer a jóvenes de todas las
          edades la oportunidad de aprender y disfrutar del baloncesto en un
          entorno profesional y divertido. Nuestras escuelas deportivas están
          diseñadas para enseñar los fundamentos del juego, promover el trabajo
          en equipo y desarrollar habilidades técnicas y tácticas, todo mientras
          se fomenta el espíritu deportivo y el respeto.
        </p>
        <p>
          Contamos con entrenadores altamente cualificados y apasionados por el
          deporte, que guiarán a los jugadores en su crecimiento personal y
          deportivo. Ya sea que tu hijo sea un principiante entusiasta o un
          jugador con experiencia, nuestras instalaciones y programas están
          adaptados para ofrecer una experiencia enriquecedora y motivadora.
        </p>
        <p>
          ¡Únete a nosotros y forma parte de la familia del CB Manzanares, donde
          el baloncesto es más que un juego, es una pasión compartida! Para
          apuntarse en las escuelas, infórmate en el{" "}
          <a
            href="https://www.google.com/maps/@39.0010399,-3.372335,3a,75y,175.92h,88.5t/data=!3m7!1e1!3m5!1s6fWKwLkSQm47Vhr-J-SyaA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D1.5032638613156024%26panoid%3D6fWKwLkSQm47Vhr-J-SyaA%26yaw%3D175.92026457009575!7i16384!8i8192?coh=205410&entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
            className="custom-link"
          >
            área de deportes situado en C/Pérez Galdós S/N
          </a>
          .
        </p>
      </div>

      <div className="button-container">
        <button className="image-button" onClick={handleButtonClick}>
          Ver Imágenes de las Escuelas
        </button>
      </div>
    </div>
  );
};

export default MyComponent;
