import React from 'react';
import LazyLoad from 'react-lazyload';
import './Patrocinador2.css';
import casaristori from '../../assets/casaristori.jpg';
import dentarium from '../../assets/DENTARIUM.jpg';
import cervantes from '../../assets/LOGO NUEVO CERVANTES (2).png';
import valhalla from '../../assets/Valhalla Banner-PhotoRoom.png';
import sede from '../../assets/SEDE BAR CAFETERÍA.jpg';
import agromarquitos from '../../assets/colaboradores/AGROMARQUITOS (2).jpg';
import asesoriaPymes from '../../assets/colaboradores/ASESORIA DE PYMES.jpg';
import autoescuelaCentro from '../../assets/colaboradores/autoescuela centro (2).jpg';
import capilla from '../../assets/colaboradores/CAPILLA.png';
import crespo from '../../assets/colaboradores/CRESPO.png';
import desmontes from '../../assets/colaboradores/DESMONTES.jpg';
import floristeria from '../../assets/colaboradores/FLORISTERÍA PARAISO NEGRO.JPEG';
import fruteria from '../../assets/colaboradores/FRUTERIA MANZANARES1.png';
import invirpa from '../../assets/colaboradores/INVIRPA.png';
import befit from '../../assets/colaboradores/befit.jpg';
import mariacalero from '../../assets/colaboradores/MARIA CALERO.png';
import marquez from '../../assets/colaboradores/MARQUEZ.jpg';
import marquezCarne from '../../assets/colaboradores/MARQUEZ CARNE.jpg';
import mueblesRos from '../../assets/colaboradores/MUEBLES ROS.jpeg';
import pedromateo from '../../assets/colaboradores/pedro mateo.jpg';
import pepes from '../../assets/colaboradores/PEPES.jpg';


const sponsors = [
  { name: 'Ristori', url: 'https://www.booking.com/searchresults.es.html?aid=318615&label=New_Spanish_ES_ES_21463012105-LPwjRe8TaU%2Ats8W_n17MsAS640874830969%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg&gclid=CjwKCAjwzIK1BhAuEiwAHQmU3pF9TXEyDToICsbpDvgrBS-dTNhi9VOHG8XAHABjqHiGMxaXYKNJRBoCHW0QAvD_BwE&highlighted_hotels=11158856&redirected=1&city=-390998&hlrd=no_dates&source=hotel&expand_sb=1&keep_landing=1&sid=2a5f7ac988dafbb1b497a63f7f00f47c', imgSrc: casaristori },
  { name: 'Dentarium', url: 'https://www.clinicadentarium.com/', imgSrc: dentarium },
  { name: 'Cervantes', url: 'https://www.fisioterapiacervantes.es/', imgSrc: cervantes },
  { name: 'Valhalla', url: 'https://www.instagram.com/valhalla.rock.bar/?__d=1%2F', imgSrc: valhalla },
  { name: 'Sede', url: 'https://www.facebook.com/padellasede/?locale=es_ES', imgSrc: sede },
  { name: 'Agromarquitos', url: 'https://www.einforma.com/informacion-empresa/agromarquitos', imgSrc: agromarquitos },
  { name: 'Asesoria Pymes', url: 'https://asesoriadepymes.com/', imgSrc: asesoriaPymes },
  { name: 'Autoescuela Centro', url: 'https://www.academiadeltransportista.com/autoescuela/autoescuela-centro-2/', imgSrc: autoescuelaCentro },
  { name: 'Capilla', url: 'https://www.facebook.com/lacapilla.discopub/?locale=es_ES', imgSrc: capilla },
  { name: 'Crespo', url: 'https://www.instagram.com/alcrespotrainer/', imgSrc: crespo },
  { name: 'Desmontes', url: 'https://www.losdesmontesmembrilla.com/', imgSrc: desmontes },
  { name: 'Floristeria Paraiso Negro', url: '', imgSrc: floristeria },
  { name: 'Fruteria Manzanares', url: '', imgSrc: fruteria },
  { name: 'Invirpa', url: 'https://invirpa.com/', imgSrc: invirpa },
  { name: 'Be Fit', url: 'https://www.facebook.com/befitcentrodeportivo/?locale=es_ES', imgSrc: befit },
  { name: 'Maria Calero', url: 'https://www.centroclinicomariacalero.com/', imgSrc: mariacalero },
  { name: 'Marquez', url: 'https://www.autocaresmarquez.com/', imgSrc: marquez },
  { name: 'Marquez Carne', url: 'https://www.facebook.com/p/C%C3%A1rnicas-M%C3%A1rquez-100063764041900/?locale=es_LA', imgSrc: marquezCarne },
  { name: 'Muebles Ros', url: 'https://www.facebook.com/MueblesRosSL/?locale=es_ES', imgSrc: mueblesRos },
  { name: 'Pedro Mateo', url: 'https://www.panaderiapedromateos.es/', imgSrc: pedromateo },
  { name: 'Pepes', url: '', imgSrc: pepes },

];


// Divide el array de sponsors en tres grupos
const groupSize = Math.ceil(sponsors.length / 3);
const firstGroup = sponsors.slice(0, groupSize);
const secondGroup = sponsors.slice(groupSize, groupSize * 2);
const thirdGroup = sponsors.slice(groupSize * 2);

const Sponsors = () => {
  return (
    <div>
      <div className="secondary-sponsors-container">
        {firstGroup.map((sponsor, index) => (
          <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
            <LazyLoad height={100} offset={100}>
              <img src={sponsor.imgSrc} alt={sponsor.name} className="secondary-sponsor-logo" />
            </LazyLoad>
          </a>
        ))}
      </div>

      <div className="secondary-sponsors-container">
        {secondGroup.map((sponsor, index) => (
          <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
            <LazyLoad height={100} offset={100}>
              <img src={sponsor.imgSrc} alt={sponsor.name} className="secondary-sponsor-logo" />
            </LazyLoad>
          </a>
        ))}
      </div>

      <div className="secondary-sponsors-container">
        {thirdGroup.map((sponsor, index) => (
          <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
            <LazyLoad height={100} offset={100}>
              <img src={sponsor.imgSrc} alt={sponsor.name} className="secondary-sponsor-logo" />
            </LazyLoad>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sponsors;
