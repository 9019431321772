import React from 'react';
import Equipo from './Equipo';

import entrenador from './fotossenior/1-min.png';
import entrenadorHover from './fotossenior/2-min.png';
import danielPerez from './fotossenior/Daniel Perez - Alero.png';
import danielPerezHover from './fotossenior/Daniel Perez 2.png';
import aaronCotillo from './fotossenior/Aaron Cotillo - Ala Pivot.png';
import aaronCotilloHover from './fotossenior/Aaron Cotillo 2.png';
import anasKhanji from './fotossenior/Anas Khanji - Escolta.png';
import anasKhanjiHover from './fotossenior/Anas Khanji 2.png';
import fernandoSanz from './fotossenior/Fernando Sanz - Ala Pivot.png';
import fernandoSanzHover from './fotossenior/Fernando Sanz 2.png';
import jesusMartin from './fotossenior/Jesus Martin - Base.png';
import jesusMartinHover from './fotossenior/Jesus Martin 2.png';
import marcosSantos from './fotossenior/Marcos Santos - Ala Pivot.png';
import marcosSantosHover from './fotossenior/Marcos Santos 2.png'; 
import moisesMolina from './fotossenior/Moises Molina - Pivot.png';
import moisesMolinaHover from './fotossenior/Moises Molina 2.png';
import pacoElipe from './fotossenior/Paco Elipe - Alero.png';
import pacoElipeHover from './fotossenior/Paco Elipe 2.png';
import MartinNavarro from './fotossenior/MartinNavarro1-Alero.png';
import MartinNavarroHover from './fotossenior/MartinNavarro2.png';
import sergioSanchez from './fotossenior/sergioSanchez1-AlaPivot.png';
import sergioSanchezHover from './fotossenior/sergioSanchez2.png';
import alejandroFierrez from './fotossenior/Alejandro Fierrez - Pivot.png';
import alejandroFierrezHover from './fotossenior/Alejandro Fierrez 2.png';
import gustavoRodriguez from './fotossenior/Gustavo Rodriguez Base.png';
import gustavoRodriguezHover from './fotossenior/Gustavo Rodriguez 2.png';
import samuelFernandez from './fotossenior/Samuel Fernandez - Escolta.png';
import samuelFernandezHover from './fotossenior/Samuel Fernandez 2.png';
import gabrielLorente from './fotossenior/Gabriel Lorente - Base.png';
import gabrielLorenteHover from './fotossenior/Gabriel Lorente 2.png';

const teamAPlayers = [
  { image: entrenador, hoverImage: entrenadorHover, number: "", name: 'Pablo Serrano', position: 'ENTRENADOR' },
  { image: danielPerez, hoverImage: danielPerezHover, number: "00", name: 'Daniel Pérez', position: 'ALERO' },
  { image: aaronCotillo, hoverImage: aaronCotilloHover, number: 8, name: 'Aarón Cotillo', position: 'ALA PIVOT' },
  { image: anasKhanji, hoverImage: anasKhanjiHover, number: 44, name: 'Anas Khanji', position: 'ESCOLTA' },
  { image: fernandoSanz, hoverImage: fernandoSanzHover, number: 32, name: 'Fernando Sanz', position: 'ALA PIVOT' },
  { image: jesusMartin, hoverImage: jesusMartinHover, number: 9, name: 'Ricky', position: 'BASE' },
  { image: MartinNavarro, hoverImage: MartinNavarroHover, number: 6, name: 'Burgui', position: 'ALERO' },
  { image: sergioSanchez, hoverImage: sergioSanchezHover, number: 13, name: 'Sergio', position: 'ALA PIVOT' },
  { image: marcosSantos, hoverImage: marcosSantosHover, number: 7, name: 'Marcos Santos', position: 'ALA PIVOT' },
  { image: moisesMolina, hoverImage: moisesMolinaHover, number: 22, name: 'Moisés Molina', position: 'PIVOT' },
  { image: pacoElipe, hoverImage: pacoElipeHover, number: 18, name: 'Paco Elipe', position: 'ALERO' },
  { image: alejandroFierrez, hoverImage: alejandroFierrezHover, number: 17, name: 'Alejandro Fierrez', position: 'PIVOT' },
  { image: gustavoRodriguez, hoverImage: gustavoRodriguezHover, number: 24, name: 'Gustavo Rodríguez', position: 'BASE' },
  { image: samuelFernandez, hoverImage: samuelFernandezHover, number: 5, name: 'Samuel Fernández', position: 'ESCOLTA' },
  { image: gabrielLorente, hoverImage: gabrielLorenteHover, number: 15, name: 'Gabriel Lorente', position: 'BASE' },
];

teamAPlayers.sort((a, b) => a.number - b.number);

const TeamA = () => {
  return (
    <Equipo teamName="Team A" players={teamAPlayers} />
  );
};

export default TeamA;
