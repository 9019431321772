import React from "react";
import LazyLoad from "react-lazyload";
import { useNavigate } from "react-router-dom";
import image1 from "../../assets/escuela1.jpg";
import image2 from "../../assets/escuela2.jpg";
import image3 from "../../assets/escuela3.jpg";
import image4 from "../../assets/escuela4.jpg";
import image5 from "../../assets/escuela5.jpg";
import "./styles.css";

const EscuelaImages = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="escuela-images-gallery-container">
      <button className="escuela-images-back-button" onClick={handleBackClick}>
        Volver
      </button>
      <h2 className="escuela-images-titulo">Galería de Imágenes de las Escuelas</h2>
      <div className="escuela-images-gallery">
        <div className="escuela-images-row">
          <LazyLoad height={200} offset={100}>
            <div className="escuela-images-container">
              <img src={image1} alt="Infantil Masculino" className="escuela-images-image" />
              <p className="escuela-images-title">Infantil Masculino</p>
            </div>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <div className="escuela-images-container">
              <img src={image2} alt="Alevín" className="escuela-images-image" />
              <p className="escuela-images-title">Alevín</p>
            </div>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <div className="escuela-images-container">
              <img src={image3} alt="Benjamín" className="escuela-images-image" />
              <p className="escuela-images-title">Benjamín</p>
            </div>
          </LazyLoad>
        </div>
        <div className="escuela-images-row escuela-images-row-center">
          <LazyLoad height={200} offset={100}>
            <div className="escuela-images-container">
              <img src={image4} alt="Cadete" className="escuela-images-image" />
              <p className="escuela-images-title">Cadete</p>
            </div>
          </LazyLoad>
          <LazyLoad height={200} offset={100}>
            <div className="escuela-images-container">
              <img src={image5} alt="Cadete Femenino" className="escuela-images-image" />
              <p className="escuela-images-title">Cadete Femenino</p>
            </div>
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default EscuelaImages;
