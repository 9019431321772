import React from 'react';
import LazyLoad from 'react-lazyload';
import './Patrocinadores.css';
import tosfrit from '../../assets/tosfrit.png';
import noos from '../../assets/noos.png';
import alm from '../../assets/ALM.jpeg';
import merco from '../../assets/MERCOMANCHA.jpg';
import cross from '../../assets/cross schools.jpg';

const sponsors = [
  { name: 'TOSFRIT', url: 'https://www.tosfrit.es/', imgSrc: tosfrit },
  { name: 'NOOS', url: 'https://www.noos-solutions.es/', imgSrc: noos },
  { name: 'ALM', url: 'https://www.alminmobiliaria.com/', imgSrc: alm },
  { name: 'MERCOMANCHA', url: 'https://www.facebook.com/p/Mercomancha-100064013411489/', imgSrc: merco },
  { name: 'CROSS', url: 'https://www.crossroadsschool.net/', imgSrc: cross },
];

const Sponsors = () => {
  return (
    <div className="primary-sponsors-container">
      {sponsors.map((sponsor, index) => (
        <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
          <LazyLoad height={100} offset={100}>
            <img src={sponsor.imgSrc} alt={sponsor.name} className="primary-sponsor-logo" />
          </LazyLoad>
        </a>
      ))}
    </div>
  );
};

export default Sponsors;
