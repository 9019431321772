import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Plantilla.css';

const BasketballSections = () => {
  const navigate = useNavigate();

  const handleSectionClick = (section) => {
    window.scrollTo(0, 0); // Scroll to top before navigating
    if (section === 'Senior') {
      navigate('/teamA');
    } else if (section === 'Junior Masculino') {
      navigate('/teamB');
    }
  };

  return (
    <div className="container">
      <h2>SECCIONES DEL CLUB</h2>
      <div className="basketball-sections">
        <div className="section" onClick={() => handleSectionClick('Senior')}>
          <div className="content">
            <h3>Senior</h3>
          </div>
        </div>
        <div className="section" onClick={() => handleSectionClick('Junior Masculino')}>
          <div className="content">
            <h3>Junior Masculino</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasketballSections;
