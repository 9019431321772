import React from "react";
import LazyLoad from "react-lazyload";
import "./Home.css";
import home1 from "../../assets/home1.jpg";
import home2 from "../../assets/home2.jpg";
import home3 from "../../assets/home3.jpg";

const ImagePresentation = () => {
  return (
    <div className="presentation-wrap">
      <div className="img-wrap">
        <div className="slider-text">
          <h2>Web Oficial del CB Manzanares</h2>
          <h1>PASIÓN, ÉXITO Y EQUIPO</h1>
        </div>
        <LazyLoad height={200} offset={100}>
          <img src={home1} alt="Imagen 1" />
        </LazyLoad>
      </div>

      {/**<div className="img-wrap">
        <div className="slider-text">
          <h2>Web Oficial del CB Manzanares</h2>
          <h1>APOYA AL CLUB</h1>
        </div>
        <LazyLoad height={200} offset={100}>
          <img src={home2} alt="Imagen 2" />
        </LazyLoad>
      </div>

      <div className="img-wrap">
        <div className="slider-text">
          <h2>Web Oficial del CB Manzanares</h2>
          <h1>UNIÓN Y FORTALEZA</h1>
        </div>
        <LazyLoad height={200} offset={100}>
          <img src={home3} alt="Imagen 3" />
        </LazyLoad>
      </div> */}
    </div>
  );
};

export default ImagePresentation;
