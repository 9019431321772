import React from 'react';
import LazyLoad from 'react-lazyload';
import './Patrocinador1.css';
import backgroundImage from '../../assets/patrocinador1.jpg';
import sponsorLogo from '../../assets/opticalia.png'; // Reemplaza con la ruta del logo del patrocinador
import teamLogo from '../../assets/logoblanco.png'; // Reemplaza con la ruta del logo del equipo

const Patrocinador1 = () => {
  return (
    <div className="patrocinador1-background-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <a href="https://www.opticalia.com/es/tiendas/opticalia-manzanares" target="_blank" rel="noopener noreferrer" className="patrocinador1-center-box-link">
        <div className="patrocinador1-center-box">
          <div className="patrocinador1-image-container">
            <LazyLoad height={100} offset={100}>
              <img src={sponsorLogo} alt="Sponsor Logo" className="patrocinador1-sponsor-logo" />
            </LazyLoad>
            <div className="patrocinador1-separator"></div>
            <LazyLoad height={100} offset={100}>
              <img src={teamLogo} alt="Team Logo" className="patrocinador1-team-logo" />
            </LazyLoad>
          </div>
          <div className="patrocinador1-sponsor-text">
            PATROCINADOR PRINCIPAL DE CB MANZANARES
          </div>
        </div>
      </a>
    </div>
  );
};

export default Patrocinador1;
