import React, { useState, useEffect } from 'react';
import './Noticias.css';
import noticia1 from '../../assets/fondonoticias1.jpg';
import noticia2 from '../../assets/fondoplantilla.jpg'; 
import noticia3 from '../../assets/fondonoticias3.jpg';
import noticia4 from '../../assets/fondonoticias4.jpg';


import noticia1Imagen from '../../assets/noticias/noticia13_10.jpg';
import amistosojuvenil from '../../assets/noticias/amistosojuvenil.jpg';
import noticia2110_1 from '../../assets/noticias/noticia21-10-1.jpg';
import noticia2110_2 from '../../assets/noticias/noticia21-10-2.jpg';
import noticia2110_3 from '../../assets/noticias/noticia21-10-3.png';

import noticia2910_1 from '../../assets/noticias/noticia29-10-1.jpg';
import noticia2910_2 from '../../assets/noticias/noticia29-10-2.jpg';
import noticia2910_3 from '../../assets/noticias/noticia29-10-3.jpg';

import noticia1111_1 from '../../assets/noticias/noticia11-11-1.png';

// Componente Modal para mostrar la noticia completa
const Modal = ({ show, onClose, title, date, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-noticias">
        <h2>{title}</h2>
        <p className="modal-date">{date}</p> {/* Fecha en el modal */}
        <div dangerouslySetInnerHTML={{ __html: content }} /> {/* Renderizar contenido HTML */}
        <button className="close-button" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

const Card = ({ id, name = '', date = '', imageUrl, isOpen, handleClick, isMobile, onOpenModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        flex: isHovered || isOpen ? 2 : 1,
        position: 'relative'
      }}
    >
      <div className="card-overlay"></div>
      <div className="card-content">
        <h2 className="card-title">{name}</h2>
        <p className="card-date">{date}</p> {/* Fecha en la carta */}
        <button className="card-button" onClick={() => onOpenModal(id)}>Ver noticia completa</button>
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', date: '', content: '' });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  // Contenido de las noticias
  const noticias = {
    '1': {
      title: 'Crónica 11 de noviembre',
      date: '11 de noviembre de 2024',
      content: `
       <p>Gran victoria para los de Rubén Córcoles que este choque tuvieron que lidiar con un Manzanares que salió respondón al buen inicio de los locales en el primer cuarto y que poco a poco fueron remontando hasta colocarse a solo un punto a tres minutos del final. Faltó ese punch final para consumar la proeza. Los albaceteños estuvieron fuertes en su convicción para lograr mantenerse invictos una jornada más mientras que los de Pablo Serrano siguen sin estrenarse.</p>
        <p>Jugador destacado: Sergio Sánchez-Migallón -- 25 puntos 1 Triple</p>
       <p>Gran partido el disputado en el Caba, ambos equipos demostraron su dominio en pista desde el principio del partido. Estuvo bastante reñido puesto que en cada cuarto se cambiaba las ventajas en el marcador. Al final la victoria se la llevo el equipo miguelturreño.</p>
       <p>Jugador destacado: Gustavo Rodríguez 18 puntos</p>

      <img src="${noticia1111_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    },
    '2': {
      title: 'Crónica 28 octubre',
      date: '28 de octubre de 2024',
      content: `
        <p>Ambos alevines disputaron en la localidad vecina de Membrilla un cuadrangular contra ayuntamiento de membrilla y ayuntamiento de bolaños. Tras disputar diferentes partidos los alevines de manzanares quedaron en 4o posición.</p>
        <p>Las infantiles de nuestra cantera se enfrentaron en casa contras las daimieleñas, ambos equipos jugaron bastante bien pero el equipo visitante demostró su dominio en pista y el partido acabo con una diferencia de 20 puntos a favor del equipo visitante.</p>
        <p>Los cadetes jugaron en casa también contra Daimiel, el partido estuvo dominado en todo momento por los jugadores locales y el partido finalizó con una diferencia de 34 puntos a favor de los locales.</p>
        <p>El sábado por tarde tuvo lugar el enfrentamiento entre Opticalia Manzanares y Miguel Esteban. El partido comenzó con el equipo de miguel esteban a la cabeza con una diferencia de 8 puntos. Todo el partido estuvo dominado por el quipo visitante, el equipo local cerro la diferencia de puntos a 4. Al final, el partido terminó con una diferencia de 20 puntos. Seguimos trabajando. Jugadores destacados Sergio y Ricky.</p>
        <p>El domingo por la tarde nuestro equipo junior se desplazó a la localidad almagreña. Nuestro equipo demostró su dominio en pista, nuestro equipo frenó en todo momento  al equipo local. El partido finalizó con una ventaja de 13 puntos. Jugadores destacados Gustavo y Alejandro.</p>
        <img src="${noticia2910_2}" alt="Noticia" style="width:100%; margin-top: 20px;" />
        <img src="${noticia2910_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
        <img src="${noticia2910_3}" alt="Noticia" style="width:100%; margin-top: 20px;" />
      `
    },
    '3': {
      title: 'Gran fin de semana de baloncesto',
      date: '21 de octubre de 2024',
      content: `
        <p>En la mañana del sábado 19 nuestras escuelas deportivas: alevines femeninos y masculinos se desplazaron hasta la localidad vecina de Daimiel. El primer partido fue el alevín femenino y terminó 48-12 acabando con victoria en casa. El segundo partido dio el turno al alevín B finalizando 54-14. Y para finalizar la mañana llegó el equipo alevín A demostrando su dominio en pista finalizando el partido 32-35 quedando la victoria a domicilio. </p>
        <p>El domingo 20 dió comienzo la temporada para nuestro equipo Juvenil, el pitido inicial fue a las 12h en el "Caba". El partido estuvo en todo momento en dinámica, el equipo local demostró en todo momento su dominio en cancha finalizando el partido con una ventaja de 23 puntos.</p>
        <p>En la tarde del Domingo nuestro equipo Senior se enfrentó en su segundo partido contra Prado Marianistas, el partido estuvo dominado en todo momento por los ciudadrealeños y el equipo de Serrano no pudo con ellos quedando la victoria en casa para Prado Marianistas. </p>
        <img src="${noticia2110_1}" alt="Noticia" style="width:100%; margin-top: 20px;" />
        <img src="${noticia2110_2}" alt="Noticia" style="width:100%; margin-top: 20px;" />
        <img src="${noticia2110_3}" alt="Noticia" style="width:100%; margin-top: 20px;" />

        `
    },
    '4': {
      title: 'Primer partido liga 1º Autonómica',
      date: '13 de octubre de 2024',
      content: `
        <p>El sabado 12 de octubre arrancó la liga 1°División Autonómica.</p>
      <p>Antes del partido el club quiso reconocer la gran trayectoria deportiva de Sonia Molina Prados, antigua jugadora del club que este año alcanzó llegar a los JJOO de Paris. Se le hizo entrega de una bufanda del club y de un polo oficial.</p>
      <p>El primer partido enfrentó a nuestro equipo senior contra Valbrokers CbTomelloso. El partido comenzó a favor de Tomelloso pero después estuvo muy igualado finalizando el partido 63-69.</p>
      <p>Además, el homenaje contó con la presencia de Laura Carrillo,concejala de Deportes y Paqui de la Cruz,concejala de Educación. Para finalizar el homenaje Sonia nos regalo un tiro libre</p>
      <img src="${noticia1Imagen}" alt="Noticia" style="width:100%; margin-top: 20px;" />

      `
    }
  };

  const handleOpenModal = (id) => {
    const { title, date, content } = noticias[id];
    setModalData({ title, date, content });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section className="gallery-container" id="galeria">
      <Card 
        id="1"
        name="Crónica 11 noviembre"
        date="11 de noviembre de 2024"
        imageUrl={noticia2}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
        <Card 
        id="2"
        name="Crónica 28 octubre"
        date="28 de octubre de 2024"
        imageUrl={noticia3}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="3"
        name="Gran fin de semana de baloncesto"
        date="21 de octubre de 2024"
        imageUrl={noticia4}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />
      <Card 
        id="4"
        name="Primer partido liga 1º Autonómica"
        date="13 de octubre de 2024"
        imageUrl={noticia1}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
        onOpenModal={handleOpenModal}
      />   

      {/* Modal para mostrar la noticia completa */}
      <Modal 
        show={showModal} 
        onClose={handleCloseModal} 
        title={modalData.title} 
        date={modalData.date} 
        content={modalData.content} 
      />
    </section>
  );
};

export default Gallery;
