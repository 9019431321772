import React, { useState } from "react";
import "./ElClub.css";

const ElClub = () => {
  const [year, setYear] = useState(2023);
  const seasons = ["18/19", "19/20", "20/21", "21/22", "22/23", "23/24"];

  const data = [
    {
      season: "18/19",
      senior: { category: "Categoria Zonal CLM", position: 6, type: "Senior" },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 3,
        type: "Juvenil",
      },
    },
    {
      season: "19/20",
      senior: { category: "Categoría Zonal CLM", position: 3, type: "Senior" },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 1,
        type: "Juvenil",
      },
    },
    {
      season: "20/21",
      senior: { category: "Categoría Zonal CLM", position: 2, type: "Senior" },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 2,
        type: "Juvenil",
      },
    },
    {
      season: "21/22",
      senior: {
        category: "Primera Civisión Autonómica",
        position: 7,
        type: "Senior",
      },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 1,
        type: "Juvenil",
      },
    },
    {
      season: "22/23",
      senior: {
        category: "Primera División Autonómica",
        position: 2,
        type: "Senior",
      },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 4,
        type: "Juvenil",
      },
    },
    {
      season: "23/24",
      senior: {
        category: "Primera División Autonómica",
        position: 6,
        type: "Senior",
      },
      juvenil: {
        category: "Junior Masculino Zonal CLM",
        position: 1,
        type: "Juvenil",
      },
    },
  ];

  const increaseYear = () => {
    if (year < 2023) {
      setYear((prevYear) => prevYear + 1);
    }
  };

  const decreaseYear = () => {
    if (year > 2018) {
      setYear((prevYear) => prevYear - 1);
    }
  };

  const currentData = data[year - 2018];

  return (
    <div className="el-club-container">
      <div className="el-club-info">
        <h2>El Club</h2>
        <p>
          Fundado el 23 de junio de 1992 por D. Francisco Parada Herrera, El
          Club Baloncesto Manzanares es una asociación sin ánimo de lucro cuyo
          objetivo es la promoción y práctica del baloncesto local, así como la
          competición a nivel autonómico.
        </p>
        <h3>Directiva Actual</h3>
        <div className="directiva-grid">
          <div className="directiva-card">
            <h4>Presidente</h4>
            <p>D. Agustín Fernández-Arroyo García</p>
          </div>
          <div className="directiva-card">
            <h4>Vicepresidenta</h4>
            <p>Dª. Elvira Ruiz-Escribano Mascaráque</p>
          </div>
          <div className="directiva-card">
            <h4>Secretario</h4>
            <p>D. Iván Suarez Pérez</p>
          </div>
          <div className="directiva-card">
            <h4>Tesorero</h4>
            <p>D. Luis Fernández-Arroyo García</p>
          </div>
          <div className="directiva-card">
            <h4>Vocales</h4>
            <p>Dª. María Dolores Rubio Benítez</p>
            <p>Dª. Ana María Díaz Calleja</p>
          </div>
        </div>
      </div>

      <div className="el-club-palmares">
        <h3>Palmarés</h3>
        <div className="palmares-controls">
          <button onClick={decreaseYear} disabled={year === 2018}>
            Anterior
          </button>
          <span className="year-display">{currentData.season}</span>
          <button onClick={increaseYear} disabled={year === 2023}>
            Siguiente
          </button>
        </div>
        <div className="palmares-cards">
          <div className="palmares-card">
            <div className="content">
              <h4>{currentData.senior.type}</h4>
              <p>Categoría: {currentData.senior.category}</p>
              <p>Posición: {currentData.senior.position}</p>
            </div>
          </div>
          <div className="palmares-card">
            <div className="content">
              <h4>{currentData.juvenil.type}</h4>
              <p>Categoría: {currentData.juvenil.category}</p>
              <p>Posición: {currentData.juvenil.position}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElClub;
