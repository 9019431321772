import React, { useState, useEffect } from 'react';
import './Header2.css'; // Archivo CSS para estilos
import { FaFacebook, FaTwitter, FaInstagram, FaBars, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.jpeg'; // Asegúrate de que la ruta sea correcta

const Header = () => {
    const [isAtTop, setIsAtTop] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (id) => {
        navigate('/');
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
        setIsMenuOpen(false); // Cerrar el menú cuando se hace click en un enlace
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={`header ${isAtTop ? 'at-top' : ''}`}>
            <div className="logo">
                <img src={logo} alt="Logo" className={`${isAtTop ? 'large-logo' : ''}`} />
                <div className={`menu-toggle ${isAtTop ? 'white-menu' : 'black-menu'}`} onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </div>
            </div>
            <nav className={isMenuOpen ? 'open' : ''}>
                <ul>
                    <li><Link to="#" onClick={() => handleLinkClick('home')} className={`${isAtTop ? '' : 'scrolled'}`}>Inicio</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('galeria')} className={`${isAtTop ? '' : 'scrolled'}`}>Galería</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('plantilla')} className={`${isAtTop ? '' : 'scrolled'}`}>Plantilla</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('escuelas')} className={`${isAtTop ? '' : 'scrolled'}`}>Escuelas</Link></li>
                    <li><Link to="#" onClick={() => handleLinkClick('instalaciones')} className={`${isAtTop ? '' : 'scrolled'}`}>Instalaciones</Link></li>
                </ul>
            </nav>
            {!isAtTop && (
                <div className="social-media">
                    <a href="https://www.facebook.com/share/2o74bA1AhoRjk7Nc/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </a>
                    <a href="https://x.com/CBManzanares" target="_blank" rel="noopener noreferrer">
                        <FaTwitter />
                    </a>
                    <a href="https://www.instagram.com/cbmanzanares/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                </div>
            )}
        </header>
    );
};

export default Header;
