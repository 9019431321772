import React from 'react';
import './Patrocinadores4.css';
import logos from '../../assets/logos.jpg';

const sponsors = [
  { name: 'E.Leclerc', imgSrc: logos },
];

const Sponsors = () => {
  return (
    <div className="third-sponsors-container">
      {sponsors.map((sponsor, index) => (
        <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
          <img src={sponsor.imgSrc} alt={sponsor.name} className="third-sponsor-logo" />
        </a>
      ))}
    </div>
  );
};

export default Sponsors;
