import React, { useState, useEffect } from 'react';
import cbmanzanares from '../../assets/logo.jpeg';
import tomelloso from '../../assets/escudos_equipos/tomelloso.png';
import marianistas from '../../assets/escudos_equipos/marianistas.png'
import miguelesteban from '../../assets/escudos_equipos/miguelesteban.jpeg'
import albacete from '../../assets/escudos_equipos/albacete.png'



// VER RESULTADOS AQUÍ:
//https://fbclm.net/pagina-de-grupo/?id=771

const ResultadosPartidos = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Datos de partidos
  const partidos = [
    {
      jornada: 1,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 63 },
      visitante: { nombre: 'CB Tomelloso', logo: tomelloso, puntos: 69 },
    },

    {
      jornada: 2,
      local: { nombre: 'CB Prado Marianistas', logo: marianistas, puntos: 87 },
      visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 44 },
    },
    
    {
      jornada: 3,
      local: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 41 },
      visitante: { nombre: 'CB Miguel Esteban', logo: miguelesteban, puntos: 61 },
    },
    {
      jornada: 4,
      local: { nombre: 'Albamendra Albacete Basket', logo: albacete, puntos: 60 },
      visitante: { nombre: 'CB Opticalia Manzanares', logo: cbmanzanares, puntos: 58 },
    },

    // AQUI PARA AÑADIR MÁS JORNADAS



  ];

  // Inicializa jornadaActual con la última jornada
  const [jornadaActual, setJornadaActual] = useState(partidos.length);

  // Función para cambiar la jornada
  const cambiarJornada = (direccion) => {
    if (direccion === 'previa' && jornadaActual > 1) {
      setJornadaActual(jornadaActual - 1);
    } else if (direccion === 'siguiente' && jornadaActual < partidos.length) {
      setJornadaActual(jornadaActual + 1);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Obtener el partido de la jornada actual
  const partido = partidos.find(p => p.jornada === jornadaActual);

  return (
    <div style={styles.container}>

    <p style={styles.categoria}>RESULTADOS - EQUIPO SENIOR</p>


    <div style={styles.resultadosContainer}>
        {/* Botón de jornada anterior */}
        <button onClick={() => cambiarJornada('previa')} style={styles.button}>
          {'<'}
        </button>

        {/* Mostrar el número de la jornada */}
        <h2 style={styles.jornada}>Jornada {jornadaActual}</h2>

        {/* Botón de jornada siguiente */}
        <button onClick={() => cambiarJornada('siguiente')} style={styles.button}>
          {'>'}
        </button>
      </div>

      {partido && (
        <div style={styles.partido}>
          {/* Logo y resultado del equipo local */}
          <div style={styles.equipo}>
            <img src={partido.local.logo} alt={partido.local.nombre} style={styles.logo} />
            <p>{partido.local.nombre}</p>
            <p style={styles.puntos}>{partido.local.puntos}</p>
          </div>

          {/* Resultado */}
          <p style={styles.guion}>-</p>

          {/* Logo y resultado del equipo visitante */}
          <div style={styles.equipo}>
            <img src={partido.visitante.logo} alt={partido.visitante.nombre} style={styles.logo} />
            <p>{partido.visitante.nombre}</p>
            <p style={styles.puntos}>{partido.visitante.puntos}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    padding: '20px',
    backgroundColor: '#20448c',
    backgroundImage: 'linear-gradient(to bottom right, #3e32ed, #16aef0)',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  resultadosContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  jornada: {
    fontSize: '24px',
    margin: '0 20px',
  },
  categoria: {
    fontSize:'40px',
    marginBottom:'20px',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'white',
    border: 'none',
    color: '#20448c',
    fontSize: '24px',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '30%',
  },
  partido: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    
  },
  equipo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  puntos: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  guion: {
    fontSize: '50px',
    margin: '0 20px',
  },
  
};

export default ResultadosPartidos;
