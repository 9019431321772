import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import './Galeria.css';
import galeria1 from '../../assets/galeria1.jpg';
import galeria2 from '../../assets/galeria2.jpg';
import galeria3 from '../../assets/galeria3.jpg';
import galeria4 from '../../assets/galeria4.jpg';

const Card = ({ id, name = '', description = '', imageUrl, color = '#fff', isOpen, handleClick, isMobile }) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className={`card ${isHovered || isOpen ? 'open' : ''}`}
      onClick={() => isMobile && handleClick(id)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ 
        backgroundColor: color,
        flex: isHovered || isOpen ? 2 : 1,
      }}
    >
      <div className="card-image-container">
        <LazyLoad height={200} offset={100}>
          <img src={imageUrl} alt={name} className="card-image" />
        </LazyLoad>
      </div>
      <div className="card-text">
        <h2 className="card-title">{name}</h2>
        {(isHovered || isOpen) && <p className="card-description">{description}</p>}
      </div>
    </div>
  );
};

const Gallery = () => {
  const [openCardId, setOpenCardId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  return (
    <section className="gallery-container" id="galeria">
      <Card 
        id="1"
        imageUrl={galeria1}
        isOpen={openCardId === '1'}
        handleClick={handleClick}
        isMobile={isMobile}
      />
      <Card 
        id="2"
        imageUrl={galeria2}
        isOpen={openCardId === '2'}
        handleClick={handleClick}
        isMobile={isMobile}
      />
      <Card 
        id="3"
        imageUrl={galeria3}
        isOpen={openCardId === '3'}
        handleClick={handleClick}
        isMobile={isMobile}
      />
      <Card 
        id="4"
        imageUrl={galeria4}
        isOpen={openCardId === '4'}
        handleClick={handleClick}
        isMobile={isMobile}
      />
    </section>
  );
};

export default Gallery;
